export const useNavigateToEntity = () => {
  const meetingId = useMeetingId();
  return async (
    e: CustomEvent<{
      id: string;
      type: "participant" | "team" | "daily-task-team";
    }>,
  ) => {
    if (e.detail.type === "participant") {
      return await navigateTo(RouteUtils.getMeetingParticipantComment(meetingId.value!, { participantId: e.detail.id }));
    }
    if (e.detail.type === "team") {
      return await navigateTo(
        RouteUtils.getMeetingTeam(meetingId.value!, e.detail.id),
      );
    }
    if (e.detail.type === "daily-task-team") {
      return await navigateTo(
        RouteUtils.getMeetingDailyTaskTeam(meetingId.value!, e.detail.id),
      );
    }
    throw new Error("Unknown entity type");
  };
};
